import { useNavigate } from 'react-router-dom';
import { Modal, ModalContent } from '../../ModalLayout/NewModalLayout'
import { useContext, useState } from 'react';
import { AirdropConnect } from '../../../../services/airdrop.service';
import { logConsole } from '../../../../utils/useConsole';
import { context } from '../../../../App';

const HammerProjectModal = ({ open, setOpen }) => {
    const navigate = useNavigate();
    const { telegramId, triggerNotification } = useContext(context);
    const airdropConnect = new AirdropConnect();
    const [ btn, setBtn ] = useState(false);
    const hammergamesId = "cdac47af-3665-4c61-aeb8-741a2fd336cf";
    const link = `/project-details/${hammergamesId}`

    const joinAirdrop = async () => {
        setBtn(true);
        try {
          const { data: response } = await airdropConnect.joinAidrop(
            telegramId,
            hammergamesId
          );
          if (response) {
            triggerNotification("info", `Joined Hammer Games Airdrop!`);
            localStorage.setItem("hammergames-joined", "true");
            navigate(link);
          }
          setBtn(false);
        } catch (error) {
          const errorMessage = airdropConnect.handleTheError(error);
          if(errorMessage??"".toLocaleLowerCase() == "user joined airdrop."){
            navigate(link);
          } else {
            logConsole({ errorMessage }, true);
            triggerNotification("error", errorMessage || "Unable to join airdrop!");
          }
          setBtn(false);
        }
    };

  return (
    <Modal open={open} onOpenChange={setOpen}>
        <ModalContent
            side='bottom'
            className="md:w-[24.7rem] md:mx-auto overflow-x-scroll !h-[95vh] justify-center !bg-[url('/public/images/hammer-announcement-bg.png')] !bg-cover !bg-center"
            closeBtnStyle='!bg-[#252013] h-[2.4rem] h-[2.4rem] rounded-full !opacity-100 !border-0 !border-transparent !outline-none'
        >
            <div className='relative w-full flex h-full'>
                <button 
                    disabled={btn}
                    className='outline-none border-0 w-[17.6rem] h-[4.6rem] absolute bottom-5 center-absolute-el'
                    type='button'
                    onClick={joinAirdrop}
                >
                    <img src='/images/hammer-announcement-cta.svg' alt='join project' className='w-full h-full' />
                </button>
            </div>
        </ModalContent>
    </Modal>
  )
}

export default HammerProjectModal